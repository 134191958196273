@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    box-sizing: border-box;
    margin: auto 2rem;
}

.swal-button {
    padding: 0.7rem 1.4rem;
    border-radius: 0.2rem;
    background: rgb(6 95 70);
    font-size: 1rem;
    border: 1px solid rgb(163 230 53);
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }

  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #f3f4f6 inset;
    -webkit-text-fill-color: #065f46;
}

  textarea:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #f3f4f6 inset;
    -webkit-text-fill-color: #065f46;
}


.header {
  display: flex;
  width: 15%;
  height: 90px;
  display: flex;
  left: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
  transition: .5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}


@media screen and (max-width:768px) {
  .hamburger {
      display: inline-block;
  }

  .nav-menu {
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: -100%;
      text-align: center;
      width: 100%;
      height: 100vh;
      transition: 0.3s;
      z-index: -1;
      background: rgba(255,255,255, .8);
  }

  .nav-menu.active {
      left: 0;
  }

  .nav-menu li {
      padding: 1rem 0;
  }

  .nav-menu a {
      font-size: 2rem;
  }
}